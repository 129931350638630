import React, { Fragment, useState, useEffect } from 'react';
import axioInstance from '../components/axiosInstance';
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import { Dropdown } from 'react-bootstrap'
import SweetAlert from 'sweetalert2'
import AdminDashboardHeader from '../components/layout/AdminDashboardHeader';
import AddTestProfileSidebar from '../components/AddTestProfileSidebar';

export default function GuestUsersDashboard() {
  let navigate = useNavigate();
  /*sessionStorage.setItem('admin_id', "1");
  sessionStorage.setItem('admin_role', "0");*/
  // eslint-disable-next-line
  const [data, setData] = useState([])
  const [tableRowsData, setTableRowsData] = useState([]);

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("admin_id");
    console.log(loggedInUser);
    if (loggedInUser === null) {
      navigate("/ulogin");
    } else {



      axioInstance.post(`admin/guest-users`).then(
        (response, data) => {
          setData(response.data);
          setTableRowsData(response.data);
        }
      );


      if (sessionStorage.getItem('shortName') && sessionStorage.getItem('shortName') !== null) {
        // do nothing
      } else {

        axioInstance.post(`admin/get-admin-shortname`, { email: sessionStorage.getItem('admin_email') }).then(
          (response, data) => {

            if (response.data.status === 100) {
              sessionStorage.setItem('shortName', response.data.relmanager.short_name);
            } else {
              sessionStorage.setItem('shortName', 'FINNOVATE');
            }

          }
        );

      }

    }
  }, [navigate]);


  const getViewLink = (ref_url) => {



    if (ref_url) {

      SweetAlert.fire({
        title: "REF URL",
        text: ref_url ? ref_url : 'NA',
        icon: "success"
      });

    } else {
      SweetAlert.fire({
        title: "REF URL",
        text: "NA",
        icon: "error"
      });
    }




  }

  const customStyles = {
    rows: {
      style: {
        minHeight: '65px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for the head cells
        paddingRight: '8px',
        whiteSpace: 'normal', // allows wrapping for the header
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        whiteSpace: 'normal', // allows text wrapping
        wordBreak: 'break-word', // ensures long words are broken
      },
    },
  };

  const columns = [
    {
      name: 'Action',
      selector: row => <></>,
      cell: (d) => <>         <Dropdown>
        <Dropdown.Toggle id="dropdown-basic" variant="primary" className='planstatus_dots'>
          <i className="fas fa-ellipsis-v" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {
            (d.profile_created != null && d.profile_created !== '' && d.profile_details_created != null && d.profile_details_created !== '' && d.prof_goal_summary_created != null && d.prof_goal_summary_created !== '' && d.risk_profile_default_created != null && d.risk_profile_default_created !== '') ? <><Dropdown.Item > <i className="fas fa-user text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Already Verified </Dropdown.Item></> : <><Dropdown.Item onClick={() => VerifyGuestUser(d.id, d.full_name, d.mobile, d.email, d.password, d.created_by, d.profile_created, d.user_id, d.ref_url)}> <i className="fas fa-user text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Verify User</Dropdown.Item></>
          }
          <Dropdown.Item onClick={() => getViewLink(d.ref_url)}> <i className="fas fa-id-card text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> View URL</Dropdown.Item>

        </Dropdown.Menu>
      </Dropdown></>,
    },
    {
      name: 'ID',
      selector: row => row.id,
      sortable: true,
      maxWidth: '100px', // restrict column width
    },
    {
      name: 'Name',
      selector: row => row.full_name,
      sortable: true,
      width: '265px' // Set the width of the column
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
      width: '265px' // Set the width of the column
    },
    {
      name: 'Phone',
      selector: row => row.mobile,
      sortable: true,
      width: '180px' // Set the width of the column
    },
    {
      name: 'Created On',
      selector: row => row.created,
      sortable: true,
    },
    {
      name: 'Created By',
      selector: row => row.admin_name,
      sortable: true,
    },
    {
      name: 'Plan Status',
      selector: row => row.full_name,
      sortable: true,
      cell: row => {


        let profile_created;
        let profile_created_color = 'redtext';

        let profile_details_created;
        let profile_details_created_color = 'redtext';

        let prof_goal_summary_created;
        let prof_goal_summary_created_color = 'redtext';

        let risk_profile_default_created;
        let risk_profile_default_created_color = 'redtext';

        if (row.profile_created != null && row.profile_created !== '') {
          profile_created_color = 'greentext';
          profile_created = 'profile_id';
        } else {
          profile_created = 'profile_id';
        }

        if (row.profile_details_created != null && row.profile_details_created !== '') {
          profile_details_created_color = 'greentext';
          profile_details_created = 'profile details';
        } else {
          profile_details_created = 'profile details';
        }

        if (row.prof_goal_summary_created != null && row.prof_goal_summary_created !== '') {
          prof_goal_summary_created_color = 'greentext';
          prof_goal_summary_created = 'summary';
        } else {
          prof_goal_summary_created = 'summary';
        }

        if (row.risk_profile_default_created != null && row.risk_profile_default_created !== '') {
          risk_profile_default_created_color = 'greentext';
          risk_profile_default_created = 'risk';
        } else {
          risk_profile_default_created = 'risk';
        }


        return (
          <>
            <>
              <span><span className={profile_created_color}>{profile_created} </span> <br /> <span className={profile_details_created_color}>{profile_details_created} </span> <br /> <span className={prof_goal_summary_created_color}>{prof_goal_summary_created} </span> <br /> <span className={risk_profile_default_created_color}>{risk_profile_default_created} </span></span>
            </>
          </>
        );
      },
      width: '265px', // Set the width of the column
    },
    {
      name: 'UTM Campaign',
      selector: row => row.utm_campaign,
      sortable: true,
    },
    {
      name: 'UTM Source',
      selector: row => row.utm_source,
      sortable: true,
    },
    {
      name: 'UTM Id',
      selector: row => row.utm_id,
      sortable: true,
    },
    {
      name: 'IP',
      selector: row => row.user_ip,
      sortable: true,
    },
    {
      name: 'ref_url',
      selector: row => row.ref_url,
      sortable: true,
      maxWidth: '100px'
    }

  ];


  const VerifyGuestUser = (guest_id, fullName, mobileNumber, emailAddress, password, created_by, profile_created = '', user_id = '', lead_url) => {

    SweetAlert.fire({
      title: profile_created != null && profile_created !== '' ? "Incomplete Profile" : "Verify User",
      text: profile_created != null && profile_created !== '' ? "Are you sure to verify this incomplete profile ?" : "Are you sure to verify this user as profile ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#09a635",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
      closeOnCancel: false
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        const Postdata = {
          full_name: fullName,
          name: fullName, // Keeping both for clarity, if needed
          mobile: mobileNumber,
          email: emailAddress,
          password: password,
          guest_id: guest_id || null, // Adding guestId if available
          register: 1, // Register flag
          registerVia: 1, // Register method flag
          created_by: created_by || null,// Relationship manager ID,
          delete_user: profile_created != null && profile_created !== '' ? '1' : '0',
          lead_url: lead_url,
          user_id: user_id // used for delete user
        };

        axioInstance.post(`admin/approve-guest-user`, Postdata).then(
          function (response) {
            console.log(response.data);

            if (response.data.get_plan_details_response.status === 100) {
              SweetAlert.fire({
                toast: true,
                icon: 'success',
                title: 'Guest User is Verified as Profile',
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                  toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                }
              }).then(() => {
                window.location.href = "admin-dashboard";
              })
            } else {
              SweetAlert.fire({
                toast: true,
                icon: 'error',
                title: response.data.message,
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', SweetAlert.stopTimer)
                  toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
                }
              });
            }

          }
        );

      }
    });

  }


  const [title, setTitle] = useState("");
  const onChange = async (e) => {
    setTitle(e.target.value);
    // eslint-disable-next-line
    var searchData = data.filter((item) => {


      if (
        item.full_name
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }

      if (
        item.email
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }

      if (
        item.mobile
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }


    });
    setTableRowsData(searchData);
  };




  const [isActive, setActive] = useState(false);

  const [action, setAction] = useState();

  const addProfile = () => {

    console.log('hostname', window.location);

    const url = window.location.origin + "/sign-in?q=2&ref_by=" + sessionStorage.getItem('shortName') + "&s=" + sessionStorage.getItem('admin_id');

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null

  }


  const planLogsRedirect = () => {
    window.location.href = 'plan-login-logs-dashboard';
    return;
  }

  const planEventsRedirect = () => {
    window.location.href = 'profile-event-logs-dashboard';
    return;
  }

  const AdminDashboardRedirect = () => {
    window.location.href = 'admin-dashboard';
    return;
  }

  return (
    <Fragment>
      <AdminDashboardHeader />



      <div className="innercontainer isPlanProfilesTimelinePage mb40">
        <div className="mainwrapper">
          <div className='form_title'><div className="mt30 font21 fw600 color384">Guest Users Dashboard</div></div>

          <div className='row mt25'>



            <div className='col-md-3'>
              <div className="font14 color212 fw600">Search</div>
              <input
                type="text"
                placeholder="Name Search"
                value={title}
                onChange={(e) => onChange(e)}
                style={{ width: "100%" }}
                className="form-control"
              />

            </div>


          </div>

          <div class="justify-content-left row mt35">

            <div class="col-lg-2 col-md-2">
              <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => AdminDashboardRedirect()}>Admin Dashboard</button>
            </div>

            <div class="col-lg-2 col-md-2">
              <button type="button" class="btn btn-default btn-block btnFinno" onClick={addProfile}>Add Profile</button>
            </div>
            <div class="col-lg-2 col-md-2">
              <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => { setActive(true); setAction('addTestProfile') }}> Add Testing Profile </button>
            </div>
            <div class="col-lg-2 col-md-2">
              <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => planLogsRedirect()}>Plan Login Logs</button>
            </div>





            {
              sessionStorage.getItem('admin_id') === '1' && (
                <>
                  <div class="col-lg-2 col-md-2">
                    <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => planEventsRedirect()}>Plan Events Logs</button>
                  </div>
                  <div class="col-lg-2 col-md-2">
                    <Dropdown className="">
                      <Dropdown.Toggle id="dropdown-basic" className="planstatus_dots dropdown-toggle btn btn-success">
                        Plan Reports
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href='plan-profiles-timeline'><i className="fas fa-calendar text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i> Profile Timeline</Dropdown.Item>
                        <Dropdown.Item href='plan-profiles-status'><i className="fas fa-users text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i>Profile Plan Status</Dropdown.Item>
                        <Dropdown.Item href='plan-summary-report-drilldown-month'><i className="fas fa-bar-chart text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i>Summary Month Wise</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>


                </>
              )
            }
            <div class="col-lg-2 col-md-2 mt35">

            </div>
          </div>


          <div className="">
            <div className="admin_dashboard_outer mt30">
              <div className="dashboard_table">
                <div className="graphtabs_outer mt25">
                  <div className="graph_container">
                    <div className="dashboardTable">
                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={tableRowsData}
                          pagination
                          customStyles={customStyles}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        (isActive === true && action === 'addTestProfile') && (<AddTestProfileSidebar setActive={setActive} setAction={setAction} />)
      }

    </Fragment>
  );
}