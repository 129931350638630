import React, { useEffect, useState } from 'react'
import { inWords } from '../global'
import formatAmount from 'indian-currency-formatter';


export default function GoalSummary({ summaryData, profiletermData, summaryCashflowData, error, setError }) {

    // eslint-disable-next-line
    const [goals, setGoals] = useState([]);
    // eslint-disable-next-line
    const [retirement, setRetirement] = useState([]);
    // eslint-disable-next-line
    const [summary, setSummary] = useState([]);

    const [cashflow, setCashflow] = useState([]);

    const [assumption, setAssumption] = useState([]);

    const [linked, setLinked] = useState();

    const [totalIncome, setTotalIncome] = useState(null);
    const [totalExpense, setTotalExpense] = useState(null);
    const [commitmentTotal, setCommitmentTotal] = useState(0);
    const [totalLoan, setTotalLoan] = useState(null);
    //const [yearlyRequired, setYearlyRequired] = useState();

    const [totalSaving, setTotalSaving] = useState('');
    const [thisYearNeed, setThisYearNeed] = useState();
    const [riskDesc, setRiskDesc] = useState();
    const [targetAmount, setTargetAmount] = useState();

    const [roiOptionLabel, setRoiOptionLabel] = useState();


    useEffect(() => {
        if (!summaryData) return; // Prevent execution if summaryData is missing
    
        setGoals(summaryData?.goals || []);
        setSummary(summaryData?.profgoalSummary || {});
    
        const find_retirement = summaryData?.goals?.find(
            (product) => product?.goal_type === "Retirement" && product?.relation === "self"
        );
    
        setRetirement(find_retirement || {});
    
        if (summaryData?.profgoalSummary) {
            switch (summaryData.profgoalSummary.roiOption) {
                case "1":
                    setRoiOptionLabel("As Per Risk Profile");
                    break;
                case "2":
                    setRoiOptionLabel("As Per Goals");
                    break;
                case "3":
                    setRoiOptionLabel("As Per Current Investments");
                    break;
                default:
                    setRoiOptionLabel("Default");
            }
        }
    
        setAssumption(summaryData?.assumption || {});
        setRiskDesc(summaryData?.risk_profile?.description || "");
        setTargetAmount(summaryData?.target_amount || 0);
        setLinked(summaryData?.InvestmentSummary?.c_amount || 0);
        setTotalIncome(summaryData?.incomeSum?.incomeSum || 0);
        setTotalExpense(summaryData?.expenseSum?.expenseSum || 0);
        setTotalLoan(summaryData?.emiSum?.emiSum ? parseFloat(summaryData.emiSum.emiSum) : 0);
        setThisYearNeed(summaryData?.thisyearneed || 0);
        setTotalSaving(
            (parseInt(summaryData?.incomeSum?.incomeSum) || 0) - (parseInt(summaryData?.outflow) || 0)
        );
    
        const termData = profiletermData || {};
    
        setCommitmentTotal(
            (parseInt(termData.healthpremiumAmount) || 0) +
            (parseInt(termData.spousepremiumAmount) || 0) +
            (parseInt(termData.selfpremiumAmount) || 0)
        );
    
        setCashflow(summaryCashflowData?.cashflow || []);
    
    }, [summaryData, profiletermData, summaryCashflowData]);
    


    return (
        <>
            <div className="tabContent_title font12 fw500 color7B8 pb15">MyFinsmart / <span className="color263">Goal Summary</span></div>

            <div className="font22 fw700 color0C4 pt30">Goal Summary</div>

            <div className="font14 fw500 color182 mt20">We have presented below a snapshot of all your major financial goals as shared by you. It is important to note that the Financial Plan below will largely revolve around helping you lay a roadmap for achieving your financial goals through various strategies as outlined therein.</div>

            {error && <div className="error-message text-danger font18 mt-3">{error}</div>} {/* Display error if any */}

            <div className="font14 fw600 color626 mt50">Retirement Goal</div>

            {
                retirement.length === 0 ? <p className='font14'>No Data</p> : (
                    <>
                        <div className="goalbox_outer mt20">
                            <div className="goalbox_title">
                                <div>
                                    <div className="font16 color263 fw600">{retirement.goal_name} <span className="font13 fw500 color626">- {retirement.full_name}</span></div>
                                </div>
                                <div>
                                    <div className="achivetab fw500 font12 color353">Projected Achievement <span className="color007 ml5">{retirement.achived_percentage}%</span></div>
                                </div>
                            </div>
                            <div className="goalbox_content mt20">
                                <div className="goalbox_inner">
                                    <div className="font13 fw500 color626">Current Monthly Expense</div>
                                    <div className="font24 fw500 color263">{inWords(retirement.total_abcd / 12)}</div>
                                </div>
                                <div className="goalbox_inner pr30">
                                    <div className="goalstatus_outer">
                                        <div className="fw500 font12 color182">{retirement.goal_inflation}% Assumed Inflation</div>
                                        <div className="goalstatus_bar mt10">
                                            <div className="fw500 font12 color626 mr10">{new Date().getFullYear()}</div>
                                            <div className="goalbar"><span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span></div>
                                            <div className="fw500 font12 color626 ml10">{retirement.achived_by}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="goalbox_inner">
                                    <div className="font13 fw500 color626">Total amount required</div>
                                    <div className="font24 fw500 color22a">{inWords(retirement.suggested_fund_display ? retirement.suggested_fund_display : retirement.suggested_fund).replace(/ /g, '')}</div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }



            <div className="font14 fw600 color626 mt50 mt40">CRUCIAL GOAL</div>
            {
                goals.length === 0 ? 'No Data' : (
                    <>
                        {
                            goals.map((data, index) => {
                                return (
                                    <>
                                        {
                                            (data.goal_type === "Education" || (data.goal_type === "Retirement" && data.relation !== 'self')) && (
                                                <>
                                                    <div className="goalbox_outer mt20">
                                                        <div className="goalbox_title">
                                                            <div>
                                                                <div className="font16 color263 fw600">{data.goal_name} <span className="font13 fw500 color626">- {data.full_name}</span></div>
                                                            </div>
                                                            <div>
                                                                <div className="achivetab fw500 font12 color353">Projected Achievement <span className="color007 ml5">{data.achived_percentage}%</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="goalbox_content mt20">
                                                            <div className="goalbox_inner">
                                                                <div className="font13 fw500 color626">Current value as of Today</div>
                                                                <div className="font24 fw500 color263">{inWords(data.total_abcd)}</div>
                                                            </div>
                                                            <div className="goalbox_inner pr30">
                                                                <div className="goalstatus_outer">
                                                                    <div className="fw500 font12 color182">{data.goal_inflation}% Assumed Inflation</div>
                                                                    <div className="goalstatus_bar mt10">
                                                                        <div className="fw500 font12 color626 mr10">{new Date().getFullYear()}</div>
                                                                        <div className="goalbar"><span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span></div>
                                                                        <div className="fw500 font12 color626 ml10">{data.achived_by}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="goalbox_inner">
                                                                <div className="font13 fw500 color626">Total amount required</div>
                                                                <div className="font24 fw500 color22a">{inWords(data.suggested_fund_display ? data.suggested_fund_display : data.suggested_fund).replace(/ /g, '')}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </>
                                )
                            })
                        }
                    </>
                )
            }





            <div className="font14 fw600 color626 mt50 mt40">LUXURY GOAL</div>

            {
                goals.length === 0 ? 'No Data' : (
                    <>
                        {
                            goals.map((data, index) => {
                                return (
                                    <>
                                        {
                                            (data.goal_type === "Wealth" || data.goal_type === "Vacation" || data.goal_type === "Marriage") && (
                                                <>

                                                    <div className="goalbox_outer mt20">
                                                        <div className="goalbox_title">
                                                            <div>
                                                                <div className="font16 color263 fw600">{data.goal_name} <span className="font13 fw500 color626">- {data.full_name}</span></div>
                                                            </div>
                                                            <div>
                                                                <div className="achivetab fw500 font12 color353">Projected Achievement <span className="color007 ml5">{data.achived_percentage}%</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="goalbox_content mt20">
                                                            <div className="goalbox_inner">
                                                                <div className="font13 fw500 color626">Current value as of Today</div>
                                                                <div className="font24 fw500 color263">{inWords(data.total_abcd)}</div>
                                                            </div>
                                                            <div className="goalbox_inner pr30">
                                                                <div className="goalstatus_outer">
                                                                    <div className="fw500 font12 color182">{data.goal_inflation}% Assumed Inflation</div>
                                                                    <div className="goalstatus_bar mt10">
                                                                        <div className="fw500 font12 color626 mr10">{new Date().getFullYear()}</div>
                                                                        <div className="goalbar"><span></span> <span></span> <span></span> <span></span> <span></span> <span></span> <span></span></div>
                                                                        <div className="fw500 font12 color626 ml10">{data.achived_by}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="goalbox_inner">
                                                                <div className="font13 fw500 color626">Total amount required</div>
                                                                <div className="font24 fw500 color22a">{inWords(data.suggested_fund)}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </>
                                )
                            })
                        }
                    </>)
            }




            <div className="mt50">ASSUMPTIONS</div>

            <div className="assumptionbox_outer mt25">
                <ul className="assumptionbox_list font13 fw500 color182">


                    {
                        assumption.length === 0 ? <li>
                            <div>No Data</div>
                        </li> : (
                            <>

                                <li>
                                    <div>Asset</div>
                                    <div>Growth</div>
                                </li>

                                <li>
                                    <div className="font14 fw600">Equity Investments will grow at</div>
                                    <div className="font14 fw600">{assumption.equity}%</div>
                                </li>
                                <li>
                                    <div className="font14 fw600">Debt Investments will grow at</div>
                                    <div className="font14 fw600">{assumption.debt}%</div>
                                </li>
                                <li>
                                    <div className="font14 fw600">EPF will grow at</div>
                                    <div className="font14 fw600">{assumption.epf}%</div>
                                </li>
                                <li>
                                    <div className="font14 fw600">Gold Investments will grow at</div>
                                    <div className="font14 fw600">{assumption.gold}%</div>
                                </li>
                                <li>
                                    <div className="font14 fw600">Property value will grow at</div>
                                    <div className="font14 fw600">{assumption.property}%</div>
                                </li>
                                <li>
                                    <div className="font14 fw600">Rental Income from property will grow at</div>
                                    <div className="font14 fw600">{assumption.rental}%</div>
                                </li>
                                <li>
                                    <div className="font14 fw600">Yearly investment increment</div>
                                    <div className="font14 fw600">{assumption.income_grow}%</div>
                                </li>
                            </>
                        )
                    }

                </ul>
            </div>


            <div className="mt50">OTHER ASSUMPTIONS</div>

            <div className="assumptionbox_outer mt25" style={{ 'background-color': '#f7f9fb' }}>
                <ul className="assumptionbox_list font13 fw500 color182">
                    {
                        assumption.length === 0 ? <li>
                            <div>No Data</div>
                        </li> : (
                            <>
                                <li>
                                    <div>Asset</div>
                                    <div>Growth</div>
                                </li>

                                <li>
                                    <div className="font14 fw600">Post retirement inflation</div>
                                    <div className="font14 fw600">{Math.round(assumption.post_retire_inflation)}%</div>
                                </li>
                                <li>
                                    <div className="font14 fw600">Life Expectancy</div>
                                    <div className="font14 fw600">{Math.round(assumption.life_expentancy)}Y</div>
                                </li>
                            </>)
                    }


                </ul>
            </div>

            <div className="goalsum_chart_outer mt50">
                <div className="font14 fw600 color626">GOAL SUMMARY</div>
                <div className="goalsum_chart_box mt20">
                    <div className="font16 fw600 color636">Age of Retirement : {assumption?.retire_age ? Math.round(assumption.retire_age) : 'NA'}</div>
                    <div className="goalsum_chart_devider mt20" />
                    <div className="goalbox_content mt20">
                        <div className="goalbox_inner">
                            <div className="font13 fw500 color626">Total Goal Amount</div>
                            <div className="font24 fw500 color263">{targetAmount ? targetAmount : 0}</div>
                        </div>
                        <div className="goalbox_inner alignCenter pr30">
                            <div className="font13 fw500 color626">This year’s need</div>
                            <div className="font24 fw500 color263">{inWords(thisYearNeed)}</div>
                        </div>
                        <div className="goalbox_inner">
                            <div className="font13 fw500 color626">Currently linked</div>
                            <div className="font24 fw500 color22a">{inWords(linked)}</div>
                        </div>
                    </div>
                    <div className="goalsum_chart_devider mt20" />
                    <div className="goalbox_content mt20">
                        <div className="goalbox_inner">
                            <div className="font13 fw500 color626">Yearly Ongoing</div>
                            <div className="font24 fw500 color263">{inWords(totalSaving)}</div>
                        </div>
                        <div className="goalbox_inner alignCenter pr30">
                            <div className="font13 fw500 color626">Increased yearly by</div>
                            <div className="font24 fw500 color263">{assumption?.income_grow || 0}%</div>
                        </div>
                        <div className="goalbox_inner">
                            <div className="font13 fw500 color626">ROI</div>
                            <div className="font24 fw500 color22a">{summary?.pre_return ? (summary.pre_return * 100).toFixed(2) : 0}% <strong className='font12 color263'>{roiOptionLabel}</strong></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt40 mb40" />
            <div className="font22 fw700 color0C4">Cashfow Overview</div>
            <div className="totalSaving_box mt15">
                <div>
                    <div className="inflowbox_title font12 fw600 color6d7">TOTAL INFLOW</div>
                    <div className="font24 fw500 color263 mt12">{inWords(totalIncome)}</div>
                </div>
                <div className="circleBtn_outer">
                    <div className="circleBtn">-</div>
                </div>
                <div>
                    <div className="inflowbox_title orangedot font12 fw600 color6d7">TOTAL OUTFLOW</div>
                    <div className="font24 fw500 color263 mt12">{inWords(parseInt(totalExpense) + parseInt(totalLoan) + parseInt(commitmentTotal))}</div>
                </div>
                <div className="circleBtn_outer">
                    <div className="circleBtn">=</div>
                </div>
                <div>
                    <div className="inflowbox_title bluedot font12 fw600 color6d7">TOTAL SAVING</div>
                    <div className="font24 fw500 color263 mt12">{inWords(totalIncome - (parseInt(totalExpense) + parseInt(totalLoan) + parseInt(commitmentTotal)))}</div>
                </div>
            </div>

            <div className="mt40 mb40" />

            <div className="font22 fw700 color0C4">Risk Profile</div>

            {(riskDesc || "") !== "" ? '' : <strong className='font14'>You Financial Risk Profiling is not yet done</strong>}

            <div className="riskbar_outer mt50" style={{ opacity: (riskDesc || "") !== "" ? 1 : 0.3 }}>
                <div className="riskbar_sctn">
                    {
                        riskDesc === "Very Caution" && (<div className="font13 fw500 color485" style={{ 'margin-top': '-42px', 'color': '#22AF4A' }}><img src="images/icons/tooltip.png" alt='Very Caution' /></div>)
                    }
                    <div className="riskbar_status"></div>
                    <div className="font13 fw500 color485 mt10">Very Caution</div>
                </div>
                <div className="riskbar_sctn">
                    {
                        riskDesc === "Caution" && (<div className="font13 fw500 color485" style={{ 'margin-top': '-42px', 'color': '#3CC97E' }}><img src="images/icons/tooltip.png" alt='Caution' /></div>)
                    }
                    <div className="riskbar_status"></div>
                    <div className="font13 fw500 color485 mt10">Caution</div>
                </div>
                <div className="riskbar_sctn">
                    {
                        riskDesc === "Moderate" && (<div className="font13 fw500 color485" style={{ 'margin-top': '-42px', 'color': '#FCD64F' }}><img src="images/icons/tooltip.png" alt='Moderate' /></div>)
                    }
                    <div className="riskbar_status"></div>
                    <div className="font13 fw500 color485 mt10">Moderate</div>
                </div>
                <div className="riskbar_sctn">
                    {
                        riskDesc === "Aggressive" && (<div className="font13 fw500 color485" style={{ 'margin-top': '-42px', 'color': '#FD9D4F' }}><img src="images/icons/tooltip.png" alt='Aggressive' /></div>)
                    }
                    <div className="riskbar_status"></div>
                    <div className="font13 fw500 color485 mt10">Aggressive</div>
                </div>
                <div className="riskbar_sctn">
                    {
                        riskDesc === "Very Aggressive" && (<div className="font13 fw500 color485" style={{ 'margin-top': '-42px', 'color': '#FF5942' }}><img src="images/icons/tooltip.png" alt='Very Aggressive' /></div>)
                    }
                    <div className="riskbar_status"></div>
                    <div className="font13 fw500 color485 mt10">Very Aggressive</div>
                </div>
            </div>


            <div className="mt40 mb40" />

            <div className="font18 fw600 color181 mt70">Cashflow for existing goal plans  </div>
            <div className="goalPlan_tbl mt25">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Year</th>
                                <th scope="col"><span className="dotstatus">Fresh Inv.</span></th>
                                <th scope="col"><span className="dotstatus darkyellowdot">Existing Inv.</span></th>
                                <th scope="col"><span className="dotstatus greendot">FV</span></th>
                                <th scope="col"><span className="dotstatus bluedot">Other Inc</span></th>
                                <th scope="col">Outflows</th>
                                <th scope="col">Net Amount</th>
                            </tr>
                        </thead>
                        <tbody>

                            {// eslint-disable-next-line
                                cashflow.length > 0 ?
                                    cashflow.map((item, index) => {
                                        return (
                                            <>
                                                <tr>
                                                    <td>{item.year}</td>
                                                    <td>{formatAmount(item.fresh_investment)}</td>
                                                    <td>{formatAmount(item.actual_fund)}</td>
                                                    <td>{formatAmount(item.fv)}</td>
                                                    <td>{item.other_income}</td>
                                                    <td>{formatAmount(item.outflow)}</td>
                                                    <td className={item.nett < 0 ? 'text-danger' : ''}>{formatAmount(item.nett)}</td>
                                                </tr>
                                            </>
                                        )
                                    }) : (<><tr><td colSpan={6}>No Data</td></tr></>)}


                        </tbody>
                    </table>
                </div>
            </div>

        </>
    )
}
