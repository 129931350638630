import React, { useEffect, useState } from "react";
import { ageCalculate } from "../global";

export default function PersonalInformation({ familyData, error, setError }) {
  const [family, setFamily] = useState([]);
  const [self, setSelf] = useState([]);

  useEffect(() => {
    const profile_id = sessionStorage.getItem("profile_id");

    if (!profile_id) {
      setError("Session expired. Please log in again.");
      return;
    }

    if (familyData && familyData.length > 0) {
      setFamily(familyData);

      const selfData = familyData.find((member) => member.relation === "self");
      if (selfData) {
        setSelf(selfData);
      } else {
        setError("Personal information not found.");
      }
    } else {
      setError("No family data available.");
    }

  }, [familyData, setError]);


  return (
    <>
      <div className="tabContent_title font12 fw500 color7B8 pb15">
        MyFinsmart / <span className="color263">Personal Information</span>
      </div>

      <div className="font22 fw700 color0C4 pt30">Personal Information</div>

      {
        !self &&
        (<>
          <div className="no-data-message font16 fw500 color7B8 mt-4 text-danger">
            <p>Unable to load your personal information at the moment.</p>
          </div>
        </>)
      }
      {
        error &&
        (<>
          <div className="no-data-message font16 fw500 color7B8 mt-4 text-danger">
            <p>Unable to load your personal information at the moment.</p>
          </div>
        </>)
      }

      <div className="personInfo_outer mt25">

        {family.length === 0 ? (
          <p>No Data</p>
        ) : (<>
          <div className="personInfo_title font16 fw600 color263">
            {self.full_name} <span className="color7B8">(you)</span>
          </div>
          <div className="infotab_outer mt10">
            <span className="infotab font12 fw500 color263">
              {ageCalculate(self.dob)} yrs
            </span>
            <span className="infotab font12 fw500 color263">{self?.dob}</span>
            <span className="infotab font12 fw500 color263">{self?.email}</span>
            <span className="infotab font12 fw500 color263">+91 {self?.phone}</span>
          </div>
        </>)

        }


      </div>

      <div className="person_info_tbl mt30">
        <table className="table font12">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Date of birth</th>
              <th scope="col">Age</th>
              <th scope="col">Relationship</th>
            </tr>
          </thead>
          <tbody>
            {family.length === 0 ? (
              <tr>
                <td colSpan={4}>No Data</td>
              </tr>
            ) : (
              family
                .filter((member) => member.relation !== "self")
                .map((member, key) => (
                  <tr key={key}>
                    <th scope="row">{member.full_name}</th>
                    <td>{member.dob_flag === "1" ? "NA" : member.dob}</td>
                    <td>
                      {member.age > 0
                        ? `${member.age} y`
                        : `${member.age_month} m`}
                    </td>
                    <td>{member.relation}</td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
      </div>

      <div className="font14 fw500 color182 mt45">
        As per our life stages Wealth Creation can be divided into various phases,
      </div>

      <ul className="variousList mt15 font14 color182 fw500">
        <li>Foundation Phase - Age 20 to 30</li>
        <li>Accumulation Phase - Age 30 to 55</li>
        <li>Preservation Phase - Age 55 to 75</li>
        <li>Distribution Phase - Age 75+</li>
      </ul>
    </>
  );
}