import CryptoJS from "crypto-js";
import SweetAlert from 'sweetalert2';
import formatAmount from "indian-currency-formatter";

export const dbRatio = (ratioExplain) => {

    const { outstanding_loan, current_value } = ratioExplain.da_ratio;

    SweetAlert.fire({
        title: "D/A RATIO",
        html: `
     <div style="text-align: left;">
                    <p class="font14">Your loans should be less than 1/3 of your total assets.</p>
                    <p class="font12"><strong>Outstanding Loan:</strong> ${outstanding_loan ? formatAmount(outstanding_loan) : 'NA'}</p>
                    <p class="font12"><strong>Current Value:</strong> ${current_value ? formatAmount(current_value) : 'NA'}</p>
                    <p class="font12"><strong>Formula:</strong> (outstanding_loan / current_value) * 100</p>
                </div>
  `,
        timer: 5000,
    });
}

export const emiRatio = (ratioExplain) => {

    const { emiSum, incomeSum } = ratioExplain.emi_burden;
    SweetAlert.fire({
        title: "EMI BURDEN",
        html: `
      <div style="text-align: left;">
                     <p class="font14">Your EMIs should be less than 40 % of your Income.</p>
                     <p class="font12"><strong>Total EMI:</strong> ${emiSum ? formatAmount(emiSum) : 'NA'}</p>
                     <p class="font12"><strong>Total Income:</strong> ${incomeSum ? formatAmount(incomeSum) : 'NA'}</p>
                     <p class="font12"><strong>Formula:</strong> (Total EMI / Total Income) * 100</p>
                 </div>
   `,
        timer: 5000,
    });

}

export const savingRatio = (ratioExplain) => {


    const { emiSum, incomeSum, expenseSum, lifeSum } = ratioExplain.saving_rate;
    SweetAlert.fire({
        title: "SAVING RATE",
        html: `
      <div style="text-align: left;">
                     <p class="font14">Your savings rate should be 25 % or more.</p>
                     <p class="font12"><strong>Total EMI:</strong> ${emiSum ? formatAmount(emiSum) : 'NA'}</p>
                     <p class="font12"><strong>Total Income:</strong> ${incomeSum ? formatAmount(incomeSum) : 'NA'}</p>
                     <p class="font12"><strong>Total Expense:</strong> ${expenseSum ? formatAmount(expenseSum) : 'NA'}</p>
                     <p class="font12"><strong>Total LI:</strong> ${lifeSum ? formatAmount(lifeSum) : 'NA'}</p>
                     <p class="font12"><strong>Formula:</strong> ((Total Income -  (Total Expense + Total EMI + Total LI)) /  Total Income ) * 100</p>
                 </div>
   `,
        timer: 5000,
    });

}

export const HalfLoader = () => {

    return (
        <div className="loader-container">
            <img
                src={"/images/finloader.gif"}
                alt=""
                loading={true}
                style={{
                    'width': '90px',
                    'height': '90px',
                    'opacity': '0.7',  // Set opacity to 0.8 (80%)
                    'display': 'block',
                    'margin': '0 auto',
                    'border-color': 'red'
                }}
            />
        </div>
    );

}

export const showErrorAlert = (error) => {
    let errorMessage = "An unexpected error occurred. Please try again later.";


    if (error.code === "ERR_NETWORK") {
        // Network error (e.g., no internet connection)
        errorMessage =
            "Network Error: Unable to connect to the server. Please check your internet connection and try again.";
    } else if (error.code === "ERR_BAD_RESPONSE") {
        // Network error (e.g., no internet connection)
        errorMessage =
            "API Error: Unable to get data from server. Please connect to support team";
    } else if (error.response) {
        // Server responded with a status code outside the 2xx range
        errorMessage =
            error.response?.data?.message ||
            `Error: Received status code ${error.response.status}`;
    } else if (error.message) {
        // Other errors (e.g., timeout, client errors)
        errorMessage = error.message;
    }

    SweetAlert.fire({
        icon: "error",
        title: error.code ? error.code : "Something went wrong",
        text: errorMessage,
        confirmButtonText: "OK",
    }).then(function () {

        if (errorMessage === "Token has expired") {


            sessionStorage.removeItem("token");
            sessionStorage.removeItem("profile_id");
            sessionStorage.removeItem("full_name");

            window.location.href = sessionStorage.getItem("admin_id") ? "/ulogin" : "/sign-in";

            sessionStorage.clear();


        }
    })

    console.error("Error details:", error); // Log for debugging
};

function Encryption(data) {
    const iv = 'bfcvJCbmwS0qaQRmamEyJg==';
    const key = 'WHH4nv43Let4huxP6vBqoabnE7JkpibkMf6wCGRPJBc=';

    const fkey = CryptoJS.enc.Base64.parse(key);
    const fiv = CryptoJS.enc.Base64.parse(iv);

    // Ensure the input data is a string
    const stringData = typeof data === 'string' ? data : JSON.stringify(data);

    const enc = CryptoJS.AES.encrypt(stringData, fkey, {
        iv: fiv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    const final = enc.ciphertext.toString(CryptoJS.enc.Base64);
    return final;
}

function Decryption(encryptedData) {
    const iv = 'bfcvJCbmwS0qaQRmamEyJg==';
    const key = 'WHH4nv43Let4huxP6vBqoabnE7JkpibkMf6wCGRPJBc=';

    const fkey = CryptoJS.enc.Base64.parse(key);
    const fiv = CryptoJS.enc.Base64.parse(iv);

    const decrypted = CryptoJS.AES.decrypt(
        { ciphertext: CryptoJS.enc.Base64.parse(encryptedData) },
        fkey,
        {
            iv: fiv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }
    );

    const final = decrypted.toString(CryptoJS.enc.Utf8);
    return final;
}


const nextStep = (step) => {
    if (step === 6) return;
    return (step + 1)
}

const prevStep = (step) => {
    if (step === 1) return;
    return (step - 1)
}


const nextsubStep = (step, count) => {

    if (step === count) {
        return;
    } else {
        return (step + 1);
    }

}

const prevsubStep = (step) => {
    if (step === 1) return;
    return (step - 1)
}

const logOut = () => {

    sessionStorage.removeItem("token");
    sessionStorage.removeItem("profile_id");
    sessionStorage.removeItem("full_name");

    window.location.href = "/sign-in";
}


const ageCalculate = (dob) => {

    var today = new Date();
    var birthDate = new Date(dob);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    console.log('m' + birthDate.getMonth());
    console.log('month minus' + today.getMonth() - birthDate.getMonth())
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
    }
    return age_now;
}

const investmentCategories = [
    {
        "id": 1,
        "name": "Mutual Fund",
        "type": 4,
        "sub_type": 9,
        "icon": "mutualfunds_tab",
        "checked": false
    },
    {
        "id": 2,
        "name": "Fix Deposits",
        "type": 4,
        "sub_type": 9,
        "icon": "pig_tab",
        "checked": false
    },
    {
        "id": 3,
        "name": "Stocks",
        "type": 4,
        "sub_type": 9,
        "icon": "chartbar_tab",
        "checked": false
    },
    {
        "id": 4,
        "name": "Gold",
        "type": 4,
        "sub_type": 9,
        "icon": "gold_tab",
        "checked": false
    },
    {
        "id": 5,
        "name": "Real Estate",
        "type": 4,
        "sub_type": 9,
        "icon": "office_tab",
        "checked": false
    },
    {
        "id": 6,
        "name": "PPF/EPF",
        "type": 4,
        "sub_type": 9,
        "icon": "coins_tab",
        "checked": false
    },
    {
        "id": 7,
        "name": "NPS Fund",
        "type": 4,
        "sub_type": 9,
        "icon": "moneytime_tab",
        "checked": false
    },
    {
        "id": 8,
        "name": "Other Invest.",
        "type": 4,
        "sub_type": 9,
        "icon": "plant_tab",
        "checked": false
    }
];


const stepData = [
    {
        "step": 1,
        "sub_steps": 2,
        "name": "Basic Details",
        "icon": "basicIcon",
        status: true
    }, {
        "step": 2,
        "sub_steps": 2,
        "name": "Protection",
        "icon": "protection",
        status: false
    }, {
        "step": 3,
        "sub_steps": 3,
        "name": "Goals",
        "icon": "goal",
        status: false
    },
    {
        "step": 4,
        "sub_steps": 4,
        "name": "Cashflow",
        "icon": "cashflow",
        status: false
    },
    {
        "step": 5,
        "sub_steps": 2,
        "name": "Investment",
        "icon": "rupee",
        status: false
    }
];


const apiUrl = window.location.hostname === "localhost"
    ? "https://fpapi.finnovate.in/api/" : window.location.hostname === "plan.finnovate.in" ? "https://planapi.finnovate.in/api/" : "https://fpapi.finnovate.in/api/";

const inWords = (num) => {


    let amount = Math.round(num);
    let data = 0;
    let v = '';
    let digits_count = amount.toString().length;

    if (digits_count >= 8) {
        data = amount / 10000000;
        v = 'Cr';
    } else if (digits_count === 7 || digits_count === 6) {
        data = amount / 100000;
        v = 'L';
    } else if (digits_count === 5 || digits_count === 4) {
        data = amount / 1000;
        v = 'K';
    }

    return data.toFixed(2) + ' ' + v;


}

const inFrequency = (num) => {

    let number = parseInt(num);
    let freq = '';
    switch (number) {
        case 1:
            freq = 'Yearly';
            break;
        case 12:
            freq = 'Monthly';
            break;
        case 6:
            freq = 'Half Yearly';
            break;
        case 4:
            freq = 'quarterly';
            break;
        default:
            break;
    }

    return freq;
}

const stepMaster = (num) => {

    let number = parseInt(num);
    let stepName = '';
    switch (number) {
        case 1:
            stepName = 'BasicDetails';
            break;
        case 2:
            stepName = 'Protection';
            break;
        case 3:
            stepName = 'Goals';
            break;
        case 4:
            stepName = 'Cashflow';
            break;
        case 5:
            stepName = 'Investments';
            break;
        case 6:
            stepName = 'Compeleted';
            break;
        default:
            break;
    }

    return stepName;
}

const registerVia = (num) => {

    let number = parseInt(num);
    let Name = '';
    switch (number) {
        case 1:
            Name = 'Admin';
            break;
        case 2:
            Name = 'Web';
            break;
        default:
            break;
    }

    return Name;

}

const sanitizeNumber = (num) => {

    let Number = '';
    if (num) {
        Number = num.replace(/,/g, "");
    } else {
        Number = num;
    }

    return Number;
}

const responsePopup = ({ text, open, handleConfirm }) => {
    return (
        <>
            <div className={open ? 'confirm show' : 'confirm'}>
                <div className="confirm-content">
                    <h4>CONFIRM</h4>
                    <div>
                        <h2>{text}</h2>
                        <p>This action is final...</p>
                    </div>
                </div>
                <div className="confirm-btns">
                    <button onClick={() => handleConfirm(true)}>YES</button>
                    <button onClick={() => handleConfirm(false)}>NO</button>
                </div>
            </div>
            <div
                className="overlay"
                onClick={() => handleConfirm(false)}
            />
        </>
    )
}

const req_params = new URLSearchParams(window.location.search);

export { nextStep, prevStep, nextsubStep, prevsubStep, ageCalculate, apiUrl, logOut, stepData, investmentCategories, inWords, inFrequency, stepMaster, registerVia, sanitizeNumber, responsePopup, Encryption, Decryption, req_params }

