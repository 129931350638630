import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../axiosInstance';
import SweetAlert from 'sweetalert2'
import OtpInput from 'react-otp-input';
//import axios from 'axios';
//import { Encryption } from '../global';

export default function RegisterVerify({ setLoginStep, setLoginType, signupVerifyScreen, setSignupVerifyScreen, signupVerifyPostData, setSignupVerifyPostData, setPopupContent, setShow, setPopupIcon, setPopupTitle }) {

  const { handleSubmit } = useForm();
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const [emailOtpMessage, setEmailOtpMessage] = useState();

  
  const onSubmit = async (FormData) => {
    try {
      setButtonSpinner(true);
  
      const Postdata = {
        email: signupVerifyPostData.emailAddress,
        mobile: signupVerifyPostData.mobileNumber,
        "emailOtp": otp[0] + '' + otp[1] + '' + otp[2] + '' + otp[3] + '' + otp[4] + '' + otp[5],
        otp_send_on: 'email',
      };
  
      const response = await axioInstance.post('otp-verify', Postdata);
  
      if (response.data.status !== 200) {
        SweetAlert.fire({
          toast: true,
          position: 'top-end',
          icon: response.data.status === 100 ? 'warning' : 'error',
          title: response.data.emailOtpMessage || 'An error occurred while registering. Please try again.',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', SweetAlert.stopTimer);
            toast.addEventListener('mouseleave', SweetAlert.resumeTimer);
          },
        });
        return;
      }
  
      if (response.data.emailVerify === 100) {
        setEmailOtpMessage('');
        const params = new URLSearchParams(window.location.search);
        const plan_admin_id = params.get('s') || '';
  
        const registrationData = {
          name: signupVerifyPostData.fullName,
          mobile: signupVerifyPostData.mobileNumber,
          email: signupVerifyPostData.emailAddress,
          password: signupVerifyPostData.password,
          register: 1,
          registerVia: 2,
          rel_manager: plan_admin_id,
        };
  
        const regResponse = await axioInstance.post('profile-registration-verify-step', registrationData);
        
        if (regResponse.data.status === 100) {
          const otpData = {
            full_name: signupVerifyPostData.fullName,
            email: signupVerifyPostData.emailAddress,
            mobile: signupVerifyPostData.mobileNumber,
            otp_send_on: 'mobile',
          };
          await axioInstance.post('send-otp-mobile', otpData);
          setSignupVerifyScreen('mobile');
        }
      } else {
        setEmailOtpMessage(response.data.emailOtpMessage);
      }
    } catch (error) {
      if (error.response?.data?.data?.password) {
        SweetAlert.fire({
          toast: true,
          icon: 'error',
          title: error.response.data.data.password[0],
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', SweetAlert.stopTimer);
            toast.addEventListener('mouseleave', SweetAlert.resumeTimer);
          },
        });
      } else if (error.response?.data?.data?.email) {
        setShow(true);
        setPopupContent(error.response.data.data.email[0]);
      } else {
        console.error('Unexpected error:', error);
        SweetAlert.fire({
          toast: true,
          icon: 'error',
          title: 'Something went wrong. Please try again later.',
          position: 'top-right',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } finally {
      setButtonSpinner(false);
    }
  };

  

  const backbtn = () => {
    setSignupVerifyScreen(false);
  }

  // eslint-disable-next-line
  const [otpError, setOtpError] = useState();
  //const [otp, setOtp] = useState(new Array(6).fill(""));

  const [otp, setOtp] = useState('');


  // eslint-disable-next-line
  const OtphandleChange = (element, index) => {

    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [minutes, seconds]);

  const resendOTP = () => {
    setEmailOtpMessage('');
    const Otpdata = {
      "full_name": signupVerifyPostData.fullName,
      "email": signupVerifyPostData.emailAddress,
      "mobile": signupVerifyPostData.mobileNumber,
      "otp_send_on": 'email'
    };

    axioInstance.post(`send-otp`, Otpdata).then(
      function (response, data) {
        setOtp('');
        setMinutes(1);
        setSeconds(30);
      }
    )


  };

  return (
    <Fragment>
      <div className="logincard">
        <div className="logincard_title pt30 pb30 pl35 pr35">
          <div className="font18 color212 fw600">Verify Your Email Address</div>
          <div className="font13 fw500 color626">OTP has been sent to Email {signupVerifyPostData.emailAddress.slice(0, 8) + signupVerifyPostData.emailAddress.slice(0).replace(/.(?=...)/g, '*')}</div>
          <button type="button" className="btn btn-outline-primary btn-sm" onClick={backbtn}><span className="blueleftarrow mr5" /> Back</button>
        </div>

        <div className="p35">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="font14 fw600 color212">Enter OTP to continue. <span className="colorfa8">*</span></div>
            <div className="mt12 otpfield">

              <OtpInput
                className={"otpfield"}
                name="otp"
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span> </span>}
                renderInput={(props) => <input {...props} className="otp-field otp-field-npm" />}
              />

            </div>
            <div className="error mt5 font13 coloreb4">{emailOtpMessage ? emailOtpMessage : otpError}</div>

            <div className="otptext font13 color626 mt30">

              {seconds > 0 || minutes > 0 ? (
                <p>
                  Resend OTP in: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
              ) : (
                <>Did not receive the OTP? <span className="color0d6 pointer" onClick={resendOTP}>Resend again</span></>
              )}
            </div>

            <button type={buttonSpinner ? "button" : "submit"} disabled={buttonSpinner} className="btn btn-primary btn-lg btn-block font13 fw500 mt20 continuebtn">{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Verify Email"}</button>


          </form>


          <div className="logintnctext font12 fw500 color485 mt35">
            By continuing, you agree with <a href='https://www.finnovate.in/terms-conditions' target='_blank' rel="noreferrer" className="color06d">Terms of Use</a> of Finnovate Financial Services Pvt Ltd. Our <a href='https://www.finnovate.in/privacy-policy' target='_blank' rel="noreferrer" className="color06d">Privacy Policy</a> describes how data is handled at Finnovate.
          </div>

        </div>


      </div>
    </Fragment >
  )
}
