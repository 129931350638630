import React, { useEffect, useState } from 'react';
import axioInstance from '../axiosInstance';
import formatAmount from 'indian-currency-formatter';
import { Encryption } from '../global';

export default function RiskProfileSummary({ summaryData, lifeCycleData, planStatusHistoryData, riskProfileData, error, setError, finnfitData }) {

  const [planHistory, setPlanHistory] = useState([]);
  const [planLatestStatus, setPlanLatestStatus] = useState([]);
  const [createdOn, setCreatedOn] = useState();
  const [riskHistory, setRiskHistory] = useState([]);
  const [profileId, setProfileId] = useState();
  const [sharedHistory, setSharedHistory] = useState([]);
  const [historyToggle, setHistoryToggle] = useState(false);
  const [finnfitHistory, setFinnfitHistory] = useState([]);

  const [selectedFinnfitId, setSelectedFinnfitId] = useState(null);
  const [selectedQnaData, setSelectedQnaData] = useState([]);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedAge, setSelectedAge] = useState(null);

  const handleViewClick = (id, name, age, qnaData) => {
    setSelectedName(name);
    setSelectedAge(age);
    setSelectedFinnfitId(id); // Set the selected Finnfit ID
    setSelectedQnaData(JSON.parse(qnaData)); // Set the corresponding Q&A data

  };

  const handleViewPDF = (finnfitId, titleArr) => {
    console.log('id', finnfitId, 'titleArr', titleArr);


    var version = 1;
    if (titleArr ===
      '["Cashflow", "Taxation", "GoalsPlanning", "LoanManagement", "RiskManagement", "WealthSuccession"]'
    ) {
      version = 1;
    } else if (titleArr ===
      '["Investments", "GoalsPlanning", "EstatePlanning", "LoanManagement", "BudgetingTaxation", "InsurancePlanning"]'
    ) {
      version = 3;
    } else if (titleArr ===
      '["Investments", "GoalsPlanning", "LoanManagement", "WealthSuccession", "BudgetingTaxation", "InsurancePlanning"]'
    ) {
      version = 3;
    }


    var action_url = (version === 1 ? 'finnfit-report/version1' : version === 2 ? 'finnfit-report/version2' :
      'finnfit-report/version3')


    axioInstance.post(action_url, {
      id: Encryption(finnfitId)
    }).then(function (response) {


      if (response.data.status === 100) {
        window.open("https://restapi.finnovatelive.in/" + response.data.pdf, '_blank');
    }

    }).catch(function (error) {

      console.log(error);

    });


    console.log('version', version);
  };


  useEffect(() => {
    if (selectedFinnfitId) {
      const qnaSection = document.getElementById("qnaSection");
      if (qnaSection) {
        // Ensure smooth scroll behavior
        qnaSection.scrollIntoView({
          behavior: "smooth",
          block: "start", // This can be 'center', 'end', or 'nearest' depending on your need
        });
      }
    }
  }, [selectedFinnfitId]); // Run whenever `selectedFinnfitId` changes

  const fetchSharedHistory = async () => {


    if (lifeCycleData.status === 100) {
      setSharedHistory(lifeCycleData.rows);
    }
    setHistoryToggle(true);

  };


  const regeneratePdf = async (risk_id) => {
    try {
      await axioInstance.post(`risk-profile-pdf-regenerate`, { risk_id, profile_id: sessionStorage.getItem('profile_id') });
      window.location.reload();
    } catch (error) {
      console.error('Error regenerating PDF:', error);
    }
  };

  useEffect(() => {
    if (!planStatusHistoryData) return; // Prevents execution if data is missing

    setPlanHistory(
      planStatusHistoryData?.history ? JSON.parse(planStatusHistoryData.history) : []
    );
    setPlanLatestStatus(planStatusHistoryData?.latest_status || "");
    setCreatedOn(planStatusHistoryData?.CreatedOn || "");
    setProfileId(planStatusHistoryData?.profile_id || "");

    setRiskHistory(riskProfileData || []);

    setFinnfitHistory(finnfitData || []);

  }, [planStatusHistoryData, riskProfileData, finnfitData]);



  return (
    <>
      <div className="tabContent_title font12 fw500 color7B8 pb15">
        MyFinsmart / <span className="color263">Risk & Plan Summary</span>
      </div>
      <div className="font22 fw700 color0C4 pt30">Plan Summary</div>

      {error && <div className="error-message text-danger font18">{error}</div>} {/* Display error if any */}

      <div className="goalbox_title mt15">
        <div>
          <div className="font16 color263 fw600">
            Latest Status: <span className="font13 fw500 color626">{planLatestStatus}</span>
          </div>
        </div>
        <div>
          <div className="achivetab fw500 font12 color353">
            Created On: <span className="color007 ml5">{createdOn ? createdOn : 'NA'}</span>
          </div>
        </div>
      </div>

      {planLatestStatus.length > 0 ?
        planHistory.map((history, key) => (
          <div className="loanBox_outer mt25 p25" key={key}>
            <div className="loandtl">
              <div className="font13 fw500 color6d7">Status</div>
              <div className="font14 fw500 color182">{history.status}</div>
            </div>
            <div className="loandtl">
              <div className="font13 fw500 color6d7">Status Date</div>
              <div className="font14 fw500 color182">
                {new Date(history.status_date).toLocaleDateString('en-GB')}
              </div>
            </div>
            {sessionStorage.getItem('admin_id') === '1' && history.ref_doc && (
              <div className="loandtl">
                <div className="font13 fw500 color6d7">PDF</div>
                <div className="font14 fw500 color182">
                  <a href={`https://planapi.finnovate.in/uploads/riskpdf/${history.ref_doc}`} target="_blank" rel="noreferrer" className="color111">
                    <i className="fas fa-file-pdf fa-2x"></i>
                  </a>
                </div>
              </div>
            )}
            <div className="loandtl">
              <div className="font13 fw500 color6d7">Remark</div>
              <div className="font14 fw500 color182">{history.remark}</div>
            </div>
            {history.status === 'Plan Freezed' && (
              <>
                <div className="loandtl">
                  <div className="font13 fw500 color6d7">Monthly</div>
                  <div className="font14 fw500 color182">{formatAmount(history.monthly_amount)}</div>
                </div>
                <div className="loandtl">
                  <div className="font13 fw500 color6d7">Yearly</div>
                  <div className="font14 fw500 color182">{formatAmount(history.yearly_amount)}</div>
                </div>
                <div className="loandtl">
                  <div className="font13 fw500 color6d7">Increase By</div>
                  <div className="font14 fw500 color182">{history.increase_by} %</div>
                </div>
                <div className="loandtl">
                  <div className="font13 fw500 color6d7">History</div>
                  <div className="font14 fw500 color182">
                    <a href={`history-fp-report?id=${profileId}&doc_id=${history.udid}`}>View</a>
                  </div>
                </div>
              </>
            )}
          </div>
        )) : <p>No Data</p>}

      <div className="goalbox_title mt50">
        <div>
          <div className="font16 color263 fw600">FP Report History</div>
        </div>
        <div className="achivetab fw500 font12 color353">
          {historyToggle ? (
            <span className="color007 ml5 pointer" onClick={() => setHistoryToggle(false)}>
              Hide History
            </span>
          ) : (
            <span className="color007 ml5 pointer" onClick={fetchSharedHistory}>
              Get History
            </span>
          )}
        </div>
      </div>

      {sharedHistory.length ? (
        sharedHistory.map((history, key) => (
          <div className="loanBox_outer mt25 p25" key={key}>
            <div className="loandtl">
              <div className="font13 fw500 color6d7">Status</div>
              <div className="font14 fw500 color182">{history.status_type}</div>
            </div>
            <div className="loandtl">
              <div className="font13 fw500 color6d7">Status Date</div>
              <div className="font14 fw500 color182">
                {history.status_date
                  ? new Date(history.status_date).toLocaleDateString('en-GB')
                  : history.created
                    ? new Date(history.created).toLocaleDateString('en-GB')
                    : 'NA'}
              </div>
            </div>
            <div className="font14 fw500 color182">
              <a href={`history-fp-report?id=${history.profile_id}&doc_id=${history.doc_id}`}>View</a>
            </div>
          </div>
        ))
      ) : (
        <span>No Data Found</span>
      )}

      <div className="font22 fw700 color0C4 pt30">Risk Summary</div>

      {
        riskHistory.length === 0 ? <p className='font14'>No Data</p> :

          (<>


            {riskHistory.map((history, key) => (
              <div className="loanBox_outer mt25 p25" key={key}>
                <div className="loandtl">
                  <div className="font13 fw500 color6d7">Risk Description</div>
                  <div className="font14 fw500 color182">{history.risk_description}</div>
                </div>
                <div className="loandtl">
                  <div className="font13 fw500 color6d7">Date</div>
                  <div className="font14 fw500 color182">{new Date(history.created).toLocaleDateString('en-GB')}</div>
                </div>
                <div className="loandtl">
                  <div className="font13 fw500 color6d7">PDF</div>
                  <div className="font14 fw500 color182">
                    {history.ref_doc && (
                      <a
                        href={`https://planapi.finnovate.in/uploads/riskpdf/${history.ref_doc}`}
                        target="_blank"
                        rel="noreferrer"
                        className="color111"
                      >
                        <i className="fas fa-file-pdf fa-2x"></i>
                      </a>
                    )}
                  </div>
                </div>
                {key === 0 && <div className="inflowbox_title greendot font12 fw600 color6d7">Latest</div>}
                {sessionStorage.getItem('admin_id') === '1' && history.ref_doc && (
                  <div className="greendot font12 fw600 color6d7 pointer" onClick={() => regeneratePdf(history.risk_id)}>
                    Regenerate
                  </div>
                )}
              </div>
            ))}

          </>)
      }



      <div className="font22 fw700 color0C4 pt30 mt15">Finnfit Report Summary</div>

      {finnfitHistory.length > 0 ? (
        finnfitHistory.map((history, key) => (
          <div className="loanBox_outer mt25 p25" key={key} style={history.id === selectedFinnfitId ? { borderColor: '#89DCA0' } : {}}>

            <div className="loandtl">
              <div className="font13 fw500 color6d7">Name</div>
              <div className="font14 fw500 color182">{history.name}</div>
            </div>
            <div className="loandtl">
              <div className="font13 fw500 color6d7">Score</div>
              <div className="font14 fw500 color182">{history.score}</div>
            </div>
            <div className="loandtl">
              <div className="font13 fw500 color6d7">Created</div>
              <div className="font14 fw500 color182">
                {new Date(history.created).toLocaleDateString("en-GB")}
              </div>
            </div>


            {key === 0 ? (<div className="loandtl"><div className="inflowbox_title greendot font12 fw600 color6d7">Latest</div></div>) : (<div className="loandtl"><div className="inflowbox_title orangedot font12 fw600 color6d7">History</div></div>)}
            <div className="font14 fw500 color182">
              <span className="pointer font12 mr-3" onClick={() => handleViewClick(history.id, history.name, history.age_range, history.qna)}>
                <i class="fa fa-eye fa-2x" aria-hidden="true"></i>

              </span>
              <span className="pointer font12" onClick={() => handleViewPDF(history.id, history.version)}>
                <i class="fa-solid fa-file-pdf fa-2x"></i>
              </span>
            </div>
          </div>
        ))
      ) : (
        <p>No Data</p>
      )}

      <div id="qnaSection">
        {selectedFinnfitId && selectedQnaData && (
          <div>

            <div className="goalbox_title mt50 mb15">
              <div>
                <div className="font16 color263 fw600">QNA View</div>
              </div>
              <div className="achivetab fw500 font12 color353">

                <span className="color007 ml5 pointer" onClick={() => { setSelectedFinnfitId(null); setSelectedQnaData([]) }}>
                  Hide
                </span>

              </div>
            </div>



            <div>
              <h5 class="card-title mb-0">What's your Full Name ?</h5>
              <br />
              <div>
                <p className='text-primary'><strong>{selectedName}</strong></p>
              </div>
            </div>
            <br />
            <br />
            <div>
              <h5 class="card-title mb-0">Age Range</h5>
              <br />
              <div>
                <p className='text-primary'><strong>{selectedAge}</strong></p>
              </div>
            </div>
            <br />
            <br />
            {/* Loop over Q&A Responses */}
            {selectedQnaData.map((response, index) => (
              <div key={index}>
                <h5 className="card-title mb-0">{response.question}</h5>
                <br />
                {response.type === "radio" ? (
                  <div>
                    {response.answers.map((option, idx) => (
                      <p
                        key={idx}
                        className={response.selected_answers.id === option.id ? 'text-success' : 'text-danger'}
                      >
                        <strong>{option.name}</strong>
                      </p>
                    ))}
                  </div>
                ) : (
                  <span className="text-primary">
                    <strong>{response.selected_answers ? response.selected_answers.name : 'Not Answered'}</strong>
                  </span>
                )}
                <br />
                <br />
              </div>
            ))}
          </div>
        )}
      </div>

    </>
  );
}
