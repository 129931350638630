import React, { Fragment, useEffect, useState } from 'react'
import axioInstance from './axiosInstance'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
//import { Encryption } from './global'

export default function AddAdminUser({ setActive, setAction }) {

    const Back = () => {
        setActive(false);
        setAction('');
    }

    const [employee, setEmployee] = useState([]);
    const [reporting, setReporting] = useState([]);


    useEffect(() => {
        axioInstance.get(`admin-users`).then(
            (response, data) => {
                setReporting(response.data.data);
                setEmployee(response.data.employee);
            }
        );
    }, [])

    // eslint-disable-next-line
    const { register, handleSubmit, setError, formState: { errors } } = useForm();


    const [responseMessage, setResponseMessage] = useState('');

    const checkEmployee = async (email) => {
        try {
            // Send GET request to check if employee exists
            const response = await axioInstance.get(`/check-employee/${email}`);

            if (response.data.success) {
                //setResponseMessage(response.data.message);
            } else {
                setResponseMessage(response.data.message);
            }
        } catch (error) {
            // Handle error response
            if (error.response) {
                setResponseMessage(error.response.data.message || 'An error occurred.');
            } else {
                setResponseMessage('Unable to connect to the server.');
            }
        }
    };

    const onSubmit = formData => {


        axioInstance.post(`/admin-users/create`, {
            fullName: formData.fullName,
            emailAddress: formData.emailAddress,
            report_to: formData.report_to,
            ser_manager: formData.ser_manager,
            created_by: sessionStorage.getItem("admin_id")
        }).then(function (response) {

            SweetAlert.fire({
                toast: true,
                icon: 'success',
                title: 'Admin User Successfully Added.',
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
            }).then(() => {
                window.location.reload();
            });

        }).catch(function (error) {

            let errorMessage = 'Unable to Add Admin User, Something went wrong.';

            if (error.response && error.response.data && error.response.data.data) {
                // If validation error is present in the response, use it
                const validationErrors = error.response.data.data;

                if (validationErrors.emailAddress && validationErrors.emailAddress.length > 0) {
                    errorMessage = validationErrors.emailAddress.join(', ');
                }
            }

            // Handle error
            SweetAlert.fire({
                toast: true,
                icon: 'error',
                title: errorMessage,
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
            }).then(() => {
                //nothing
            });
        });


    }


    return (
        <Fragment>
            <div className="rightSide_menu_outer">
                <div className="rightSide_menu">

                    <div className="rightSide_menu_header">
                        <div>
                            <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
                        </div>
                        <div className="font14 fw500 color182">
                            Add Admin
                        </div>
                        <div>&nbsp;</div>
                    </div>
                    <div className="topBanner pl25">
                        <img src='/images/rupeee.png' alt='img' className='sidebarRupeeeIcon' />
                        <div className="font18 fw600 color182">Add Admin User</div>
                        <div className="font14 fw500 color485">Finnovate Users</div>
                    </div>

                    <div className="form_prnt p20">

                        <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>
                            <span className='redtext'>{responseMessage}</span>
                            <div className="form-group">
                                <label>Admin Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    {...register("fullName", { required: "Full name is required" })}
                                    placeholder="Enter full name"
                                    autoComplete="off"
                                />
                                {errors.fullName && <span className='redtext'>{errors.fullName.message}</span>}
                            </div>

                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    {...register("emailAddress", {
                                        required: "Email address is required",
                                        pattern: {
                                            value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                            message: "Enter a valid email address"
                                        }
                                    })}
                                    placeholder="Enter email address"
                                    autoComplete="nope"
                                    onBlur={(e) => checkEmployee(e.target.value)} // Trigger on input blur
                                />
                                {errors.emailAddress && <span className='redtext'>{errors.emailAddress.message}</span>}
                            </div>

                            <div class="form-group">
                                <label>Assign Report To</label>
                                <select name="report_to" id="report_to" class="form-control" autocomplete="off" {...register("report_to", { required: "Report To is required" })}>
                                    <option disabled="" value="">Select Report To</option>
                                    {
                                        reporting.map((admin) => {

                                            return (
                                                <>
                                                    <option value={admin.admin_id}>{admin.admin_name}</option>
                                                </>
                                            )
                                        })
                                    }
                                </select>
                                {errors.report_to && <span className='redtext'>{errors.report_to.message}</span>}
                            </div>


                            <div class="form-group">
                                <label>Assign Service Manager</label>
                                <select name="ser_manager" id="ser_manager" class="form-control" autocomplete="off" {...register("ser_manager", { required: "Report To is required" })}>
                                    <option disabled="" value="">Select Service Manager</option>
                                    {
                                        employee.map((emp) => {

                                            return (
                                                <>
                                                    <option value={emp.short_name}>{emp.short_name}</option>
                                                </>
                                            )
                                        })
                                    }
                                </select>
                                {errors.ser_manager && <span className='redtext'>{errors.ser_manager.message}</span>}
                            </div>


                            <div className="saveBtn backsaveBtn">
                                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                                <button type="submit" className="btn btn-primary">Save </button>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}
