import React, { useEffect, useState } from "react";

export default function WelcomeNote({ summaryData, error }) {

  const [fullName, setFullName] = useState(summaryData?.self_data?.full_name || "Guest");

  useEffect(() => {
    setFullName(summaryData?.self_data?.full_name || "Guest");
  }, [summaryData]);

  return (
    <>
      <div className="tabContent_title font12 fw500 color7B8 pb15">
        MyFinsmart / <span className="color263">Welcome Note</span>
      </div>

      <div className="font22 fw700 color0C4 pt30">Welcome, {fullName}!</div>

      {error && <div className="error-message text-danger font18">{error}</div>} {/* Display error if any */}

      <div className="font14 fw500 color182 pt20">
        We are pleased to present you with your personalized comprehensive financial
        report. Our team continuously strives in making the financial plan document
        as relevant and realistic as possible. This Financial plan is not only for
        you but for us also. It helps us understand the factors that affect your
        decision making with respect to various expenses, savings and investments.
      </div>

      <div className="welcomeimg">
        <img src="images/fp_report_main.png" alt="welcome" />
      </div>

      <div className="font14 fw500 color182 pt40">
        The purpose of this report is to help lay out a roadmap for achieving your
        financial goals and objectives.
      </div>

      <div className="font14 fw500 color182 pt12">
        Based on the information that you have provided, we have analyzed your
        current situation and outlined an action plan that will help you achieve
        your financial goals and objectives.{" "}
      </div>

      <div className="font14 fw500 color182 pt12">
        The financial plan has the following sections. Each section is designed to
        give you a better understanding of your financial circumstances, and what is
        projected for the future.
      </div>

      <div className="goalsumm_outer mt30">
        <div className="goalsumm_left">
          <div className="font18 fw700 color0C4">Goal Summary</div>
          <ul className="goalsumList font14 fw500 color182 mt20">
            <li>Financial Statement Snapshot</li>
            <li>Loan management and plan of action</li>
            <li>Insurance need analysis and plan of action</li>
            <li>Goal wise analysis and plan of action</li>
          </ul>
        </div>
        <div className="goalsumm_right pl35 font14 color182 fw500">
          The financial plan has the following sections. Each section is designed to
          give you a better understanding of your financial circumstances, and what
          is projected for the future. As changes occur in your financial situation,
          it is important to update your personal information in order to
          re-evaluate whether you are on track to meeting your goals.
        </div>
      </div>

      <div className="font14 fw500 color182 pt40">
        If, after a thorough review of the plan, you feel you would like to make
        different assumptions, we will be happy to make adjustments based on
        whatever assumptions you may wish to adopt.
      </div>

      <div className="font14 fw500 color182 pt12">
        Periodic review will be necessary to keep your report up to date and
        pertinent to your life. While the document is aided by our proprietary
        financial planning software, a lot of human intervention has gone into
        making this financial plan a worthwhile proposition.
      </div>

      <div className="font14 fw500 color182 pt12">
        This report is meant to be educational, interactive and easy to understand.
        At any point, during or after our meeting, please feel free to engage us
        with questions.
      </div>

      <div className="font14 fw500 color182 pt70">
        All the best and let us know if you have any questions.
      </div>
      <div className="finnovatetagline font20 fw700 color263 pt15">
        <span className="finnvoate_logo mr15" /> Your trusted advisor!
      </div>
    </>

  )
}
