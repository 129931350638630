import React, { Fragment, useState, useEffect } from 'react';
import axioInstance from '../components/axiosInstance';
import { useNavigate } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import { Dropdown } from 'react-bootstrap'
import SweetAlert from 'sweetalert2'
import AdminDashboardHeader from '../components/layout/AdminDashboardHeader';
import EditAdminUser from '../components/EditAdminUser';
import AddAdminUser from '../components/AddAdminUser';
import { Encryption } from '../components/global';

export default function ManageAdminUsers() {
  let navigate = useNavigate();
  /*sessionStorage.setItem('admin_id', "1");
  sessionStorage.setItem('admin_role', "0");*/
  // eslint-disable-next-line
  const [data, setData] = useState([])
  const [tableRowsData, setTableRowsData] = useState([]);

  const [isActive, setActive] = useState(false);

  const [action, setAction] = useState();

  const [adminId, setAdminId] = useState();


  const editAdmin = (adminId) => {
    setActive(true);
    setAction('edit');
    setAdminId(adminId);
  }

  const addAdmin = () => {
    setActive(true);
    setAction('add');
  }

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("admin_id");
    console.log(loggedInUser);
    if (loggedInUser === null) {
      navigate("/ulogin");
    } else {



      axioInstance.get(`admin-users`).then(
        (response, data) => {
          setData(response.data.data);
          setTableRowsData(response.data.data);
        }
      );


      if (sessionStorage.getItem('shortName') && sessionStorage.getItem('shortName') !== null) {
        // do nothing
      } else {

        axioInstance.post(`admin/get-admin-shortname`, { email: sessionStorage.getItem('admin_email') }).then(
          (response, data) => {

            if (response.data.status === 100) {
              sessionStorage.setItem('shortName', response.data.relmanager.short_name);
            } else {
              sessionStorage.setItem('shortName', 'FINNOVATE');
            }

          }
        );

      }

    }
  }, [navigate]);


  // Function to delete an admin with confirmation
  const deleteAdmin = (adminId) => {
    // Show confirmation dialog
    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        // Proceed with deletion if confirmed
        axioInstance.post(`admin-users-delete`, { admin_id: Encryption(adminId) })
          .then(response => {
            if (response.data.success) {
              SweetAlert.fire({
                toast: true,
                icon: 'success',
                title: 'Admin deleted successfully.',
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              }).then(() => {
                window.location.reload();
              });
            } else {
              SweetAlert.fire({
                toast: true,
                icon: 'error',
                title: 'Unable to delete Admin. Something went wrong.',
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              });
            }
          })
          .catch(function (error) {
            // Handle error
            let errorMessage = 'Unable to delete Admin. Something went wrong.';
            if (error.response && error.response.data && error.response.data.message) {
              errorMessage = error.response.data.message;
            }
            SweetAlert.fire({
              toast: true,
              icon: 'error',
              title: errorMessage,
              animation: false,
              position: 'top-right',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
          });
      } else {
        // If the user cancels, show a message or do nothing
        SweetAlert.fire({
          toast: true,
          icon: 'info',
          title: 'Admin deletion cancelled.',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
    });
  }


  const customStyles = {
    rows: {
      style: {
        minHeight: '65px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for the head cells
        paddingRight: '8px',
        whiteSpace: 'normal', // allows wrapping for the header
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
        whiteSpace: 'normal', // allows text wrapping
        wordBreak: 'break-word', // ensures long words are broken
      },
    },
  };

  const columns = [
    {
      name: 'Action',
      selector: row => <></>,
      cell: (d) => <>         <Dropdown>
        <Dropdown.Toggle id="dropdown-basic" variant="primary" className='planstatus_dots'>
          <i className="fas fa-ellipsis-v" />
        </Dropdown.Toggle>

        <Dropdown.Menu>

          <Dropdown.Item onClick={() => editAdmin(d.admin_id)}> <i className="fas fa-id-card text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Edit</Dropdown.Item>
          <Dropdown.Item onClick={() => deleteAdmin(d.admin_id)}> <i className="fas fa-id-card text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Delete</Dropdown.Item>

        </Dropdown.Menu>
      </Dropdown></>,
    },
    {
      name: 'ID',
      selector: row => row.admin_id,
      sortable: true,
      maxWidth: '50px', // restrict column width
    },
    {
      name: 'Admin Name',
      selector: row => row.admin_name,
      sortable: true,
      width: '205px' // Set the width of the column
    },
    {
      name: 'Email',
      selector: row => row.email_id,
      sortable: true,
      width: '205px' // Set the width of the column
    },
    {
      name: 'Password',
      selector: row => row.pass,
      sortable: true,
      width: '150px' // Set the width of the column
    },
    {
      name: 'Role',
      selector: row => row.role === 1 ? 'Planner' : 'Admin',
      sortable: true,
    },
    {
      name: 'report_to',
      selector: row => row.report_to_name,
      sortable: true,
    },
    {
      name: 'rel_manager',
      selector: row => row.rel_manager,
      sortable: true,
    },
    {
      name: 'ser_manager',
      selector: row => row.ser_manager,
      sortable: true,
    },
    {
      name: 'created_by',
      selector: row => row.created_by,
      sortable: true,
    },
    {
      name: 'Created',
      selector: row => row.created_on,
      sortable: true,
    }

  ];

  const [title, setTitle] = useState("");
  const onChange = async (e) => {
    setTitle(e.target.value);

    // eslint-disable-next-line
    var searchData = data.filter((item) => {


      if (
        item.admin_name
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }

      if (
        item.email_id
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }



    });
    setTableRowsData(searchData);
  };


  const AdminDashboardRedirect = () => {
    window.location.href = 'admin-dashboard';
    return;
  }

  return (
    <Fragment>
      <AdminDashboardHeader />



      <div className="innercontainer isPlanProfilesTimelinePage mb40">
        <div className="mainwrapper">
          <div className='form_title'><div className="mt30 font21 fw600 color384">Admin Users Dashboard</div></div>

          <div className='row mt25'>



            <div className='col-md-3'>
              <div className="font14 color212 fw600">Search</div>
              <input
                type="text"
                placeholder="Name Search"
                value={title}
                onChange={(e) => onChange(e)}
                style={{ width: "100%" }}
                className="form-control"
              />

            </div>


          </div>

          <div class="justify-content-left row mt35">

            <div class="col-lg-2 col-md-2">
              <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => AdminDashboardRedirect()}>Admin Dashboard</button>
            </div>


            <div class="col-lg-2 col-md-2">
              <button type="button" class="btn btn-default btn-block btnFinno" onClick={() => { addAdmin() }}> Add Admin User </button>
            </div>

          </div>


          <div className="">
            <div className="admin_dashboard_outer mt30">
              <div className="dashboard_table">
                <div className="graphtabs_outer mt25">
                  <div className="graph_container">
                    <div className="dashboardTable">
                      <div className="table-responsive">
                        <DataTable
                          columns={columns}
                          data={tableRowsData}
                          pagination
                          customStyles={customStyles}
                          defaultSortFieldId={2} // Sort by Column 1 on load
                          defaultSortAsc={false} // Ascending order
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        (isActive === true && action === 'edit') && (<EditAdminUser setActive={setActive} setAction={setAction} adminId={adminId} />)
      }

      {
        (isActive === true && action === 'add') && (<AddAdminUser setActive={setActive} setAction={setAction} />)
      }



    </Fragment>
  );
}