import React from 'react';

const FinnLoader = () => {
    const img_url = `${window.location.protocol}//${window.location.host}`;

    return (
        <div className="finn-loader-wrapper">
            <img 
                className="finn-loader"
                src={`${img_url}/images/icons/finloader.gif`}
                alt="Loading..."
                loading="lazy"
            />
        </div>
    );
};

export default FinnLoader;
