import React from "react";
import { Modal, ModalBody } from "react-bootstrap";

const FinnModal = ({
    show,
    onClose,
    title,
    message,
    footer,
    isClosable = true,
    isAction = true,
    primaryButtonText = "OK",
    secondaryButtonText = "Cancel",
    onPrimaryClick,
    onSecondaryClick
}) => {


    return (
        <Modal show={show} size="lg" backdrop="static" keyboard={isClosable} centered>
            <ModalBody>
                <div className="p20">
                    {/* Close button (Only if closable) */}
                    {isClosable && (
                        <button
                            title="Close"
                            type="button"
                            className="close-button"
                            onClick={onClose}
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                background: "transparent",
                                border: "none",
                                fontSize: "24px",
                                color: "#000",
                                cursor: "pointer"
                            }}
                        >
                            <i className="fa fa-close fa-1x"></i>
                        </button>
                    )}

                    {/* Title */}
                    {title && (
                        <div className="text-left">
                            <h1 className="font24 fw700 color384">{title}</h1>
                        </div>
                    )}

                    {/* Message - Accepts JSX */}
                    <div className="text-left color485 fw500 font17">{message}</div>

                    {
                        isAction && (
                            <>
                                <div className="text-left mt30">
                                    <button
                                        className="btn mx-2 font14 color182 fw500 mt10 skipGoal"
                                        onClick={onPrimaryClick}
                                    >
                                        {primaryButtonText}
                                    </button>
                                    <button
                                        className="btn btnFinno mx-2 font14 color182 fw500 mt10"
                                        onClick={onSecondaryClick}
                                    >
                                        {secondaryButtonText}
                                    </button>
                                </div>
                            </>
                        )
                    }


                    {/* Dynamic Footer - Accepts JSX */}
                    {footer && (
                        <div className="text-left mt40 color485 fw500 font17">{footer}</div>
                    )}
                </div>
            </ModalBody>
        </Modal>
    );
};

export default FinnModal;
